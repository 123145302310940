import { Button, Col, H1, H2, Hidden, Image, Row, Section } from '@ahmdigital/ui';
import { get, isNil } from 'lodash/fp';
import React from 'react';
import styled from 'styled-components';

import BackgroundImage from './background-image';
import type { HeroSectionProps } from '../../types';
import type { UrlObjectType } from '../../../../types/sanity';

const StyledSection = styled(Section)`
  padding: 0.5rem 0 3.5rem;

  ${({ theme, hasOffer, hasSubtitle }) => `
    @media (min-width: ${theme.media.xs}) {
      max-width: 31.25rem;
    }

    @media (min-width: ${theme.media.sm}) {
      max-width: 50rem;
      padding: 13.75rem 2rem 16rem;

      ${
        hasSubtitle &&
        `
        padding: 11rem 2rem;
      `
      };

      ${
        hasOffer &&
        `
        padding: 6rem 2rem;
      `
      };
    }

    @media (min-width: ${theme.media.md}) {
      ${
        hasSubtitle &&
        `
        max-width: 40rem;
      `
      };
    }

    @media (min-width: ${theme.media.lg}) {
      max-width: 50rem;
      padding: 13rem 2rem 11.5rem;

      ${
        hasSubtitle &&
        `
        padding: 10rem 2rem;
      `
      };

      ${
        hasOffer &&
        `
        padding: 6rem 2rem;
      `
      };
    }
  `}

  .flair {
    .ctaLink {
      color: $color-purple;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const MobileImage = styled(Image)`
  margin: auto;
  object-fit: cover;
`;

const getCtaComponent = (urlObject: UrlObjectType) => {
  const { url, name } = urlObject;
  return (
    <Button
      icon={{
        name: 'arrow-right-long',
        position: 'right',
        size: 'large',
      }}
      to={url}
      variant={['flair', 'wide', 'wideSpaceBetween']}
    >
      {name}
    </Button>
  );
};

const HeroSection = ({ backgroundImage, heroOfferSection, name, subtitle, urlObject }: HeroSectionProps) => {
  const mobileImage = get('mobile', backgroundImage);
  const tabletImage = get('tablet', backgroundImage);
  const desktopImage = get('desktop', backgroundImage);

  const hasSubtitle = !isNil(subtitle);
  const activeOffers = get('props.activeOffers', heroOfferSection);
  const hasOffer = !isNil(activeOffers);

  return (
    <BackgroundImage desktop={desktopImage} tablet={tabletImage}>
      <Row isBoxed>
        <Col df>
          <Hidden md lg xl df>
            <ImageWrapper>
              <MobileImage src={mobileImage.image} />
            </ImageWrapper>
          </Hidden>
          <StyledSection hasOffer={hasOffer} hasSubtitle={hasSubtitle}>
            <H1>{name}</H1>
            {hasSubtitle && (
              <H2 looksLike="h6" variant={['regular']}>
                {subtitle}
              </H2>
            )}
            {heroOfferSection}
            <Row>
              <Col sm={12} md={6}>
                {/* @ts-expect-error - Automatic, Please fix when editing this file */}
                {getCtaComponent(urlObject)}
              </Col>
            </Row>
          </StyledSection>
        </Col>
      </Row>
    </BackgroundImage>
  );
};

export default HeroSection;
