import React from 'react';

import DiversifiedHero from '../../components/diversified-hero';
import HeroOfferSection from '../../components/hero-offer-section';
import HeroSection from '../../components/hero-section';
import ProductPropType from '../../../../types/product';
import WelcomeBackHeroSection from '../../components/welcome-back-hero';
import type { DiversifiedHeroProps, HeroSectionProps } from '../../types';
import type { Offer, Placeholder } from '../../../../components/offers/types';

type HomeHeroProps = {
  diversifiedHeroProps: DiversifiedHeroProps;
  heroSectionProps: HeroSectionProps;
  lastViewedProduct?: ProductPropType;
  lastViewedProductId?: string;
  memberNumber?: string;
  offerProps: {
    activeOffers?: Offer[];
    placeholders?: Placeholder[];
  };
  isPartnerReferral: boolean;
};

const getHomeHero = ({
  diversifiedHeroProps,
  heroSectionProps,
  lastViewedProduct,
  lastViewedProductId,
  memberNumber,
  offerProps,
  isPartnerReferral,
}: HomeHeroProps) => {
  if (memberNumber) {
    return <DiversifiedHero {...diversifiedHeroProps} />;
  }

  const heroOfferSection = (
    // @ts-expect-error - Automatic, Please fix when editing this file
    <HeroOfferSection lastViewedProduct={lastViewedProduct} isPartnerReferral={isPartnerReferral} {...offerProps} />
  );

  if (lastViewedProductId) {
    return <WelcomeBackHeroSection heroOfferSection={heroOfferSection} lastViewedProduct={lastViewedProduct} />;
  }

  return <HeroSection {...heroSectionProps} heroOfferSection={heroOfferSection} />;
};

export default getHomeHero;
