import ceiliaGlitchDesktop from './images/ceilia-glitch-desktop.gif';
import ceiliaGlitchMobile from './images/ceilia-glitch-mobile.gif';
import constants from '../../../ahm-constants';
import type { DiversifiedHeroProps, HeroSectionProps } from '../types';

export const diversifiedHeroData: DiversifiedHeroProps = {
  urlObject: {
    name: 'Manage your health cover',
    url: constants.URLS.MEMBERS.HOME,
  },
};

export const heroSectionData: HeroSectionProps = {
  backgroundImage: {
    desktop: {
      image: ceiliaGlitchDesktop.src,
    },
    mobile: {
      image: ceiliaGlitchMobile.src,
    },
    tablet: {
      image: ceiliaGlitchMobile.src,
    },
  },
  urlObject: {
    name: 'Find cover',
    url: constants.URLS.SALES.REFINE_PRICE,
  },
};
